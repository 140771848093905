import {Component, Injectable, OnInit, ViewChild} from '@angular/core'
import {MatTab, MatTabContent, MatTabGroup} from '@angular/material/tabs'
import {HammerGestureConfig} from '@angular/platform-browser'
import * as hammer from 'hammerjs'
import {BankIDVerificationItem} from '@sparbanken-syd/id-be'
import {WelcomeComponent} from '../2-welcome/welcome.component'
import {ScannerComponent} from '../3-scanner/scanner.component'
import {IdListComponent} from '../4-id-list/id-list.component'
import {HelpComponent} from '../5-help/help.component'
import {DataService} from '../services/data.service'

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  override overrides = {
    'pinch': {enable: false},
    'rotate': {enable: false}
  }

  load(): Promise<any> {
    return Promise.resolve(hammer)
  }
}

@Component({
  selector: 'spb-start',
  templateUrl: './start.component.html',
  imports: [
    MatTabGroup,
    MatTab,
    WelcomeComponent,
    ScannerComponent,
    IdListComponent,
    HelpComponent,
    MatTabContent
  ]
})
export class StartComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup: any

  constructor(private dataService: DataService) {
  }

  public ngOnInit() {
    /**
     * Get all verifications (succeeded and errors) from database and set them to a signals list
     */
    this.dataService.getMyTransactions().subscribe({
      next: (bid: BankIDVerificationItem[]) => this.dataService.bankIDVerificationItem.set(bid)
    })
  }

  public swipeLeft() {
    this.tabGroup.selectedIndex += 1 // Switch tab to the right
  }

  public swipeRight() {
    this.tabGroup.selectedIndex -= 1 // Switch tab to the left
  }
}
