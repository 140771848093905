import {HttpClient} from '@angular/common/http'
import {Injectable, signal, WritableSignal} from '@angular/core'
import {Observable} from 'rxjs'
import {BankIDVerificationItem} from '@sparbanken-syd/id-be'
import {environment} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class DataService {
  /**
   * Holds the userData that we show the user after scanning is done
   */
  public userData: WritableSignal<BankIDVerificationItem> = signal({} as any)

  /**
   * The list of all BankID Verification Data
   */
  public bankIDVerificationItem: WritableSignal<BankIDVerificationItem[]> = signal([])

  constructor(
    private httpClient: HttpClient
  ) {
  }

  public getMyTransactions(): Observable<BankIDVerificationItem[]> {
    const url = `${environment.bilAPIUrl}/user`
    return this.httpClient.get<BankIDVerificationItem[]>(url)
  }

  public checkId(scannedId: any): Observable<BankIDVerificationItem> {
    const url = `${environment.bilAPIUrl}/user`
    return this.httpClient.put<BankIDVerificationItem>(url, scannedId)
  }
}
